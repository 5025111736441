@import url('https://fonts.googleapis.com/css?family=Montserrat:Light,Regular,Medium,Semibold,ExtraBold,Black');
@import url('https://fonts.googleapis.com/css?family=JetBrains+Mono:300,400,500,600');
@import url('/node_modules/react-grid-layout/css/styles.css');
@import url('/node_modules/react-resizable/css/styles.css');

@font-face {
  font-family: 'Neometric-Light';
  src: local('Neometric-Light'), url(./ui/fonts/Fontspring-DEMO-neometricalt-light.otf) format('opentype');
}

@font-face {
  font-family: 'Neometric-Regular';
  src: local('Neometric-Regular'), url(./ui/fonts/Fontspring-DEMO-neometricalt-regular.otf) format('opentype');
}

@font-face {
  font-family: 'Neometric-Medium';
  src: local('Neometric-Medium'), url(./ui/fonts/Fontspring-DEMO-neometricalt-medium.otf) format('opentype');
}

@font-face {
  font-family: 'Neometric-Bold';
  src: local('Neometric-Bold'), url(./ui/fonts/Fontspring-DEMO-neometricalt-bold.otf) format('opentype');
}

@font-face {
  font-family: 'Neometric-ExtraBold';
  src: local('Neometric-ExtraBold'), url(./ui/fonts/Fontspring-DEMO-neometricalt-extrabold.otf) format('opentype');
}

@font-face {
  font-family: 'Neometric-Black';
  src: local('Neometric-Black'), url(./ui/fonts/Fontspring-DEMO-neometricalt-black.otf) format('opentype');
}

@font-face {
  font-family: 'Neometric-Heavy';
  src: local('Neometric-Heavy'), url(./ui/fonts/Fontspring-DEMO-neometricalt-heavy.otf) format('opentype');
}
